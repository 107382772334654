import { IApiResponse, IAsset } from "../common/commonTypes";
import { fetchWrapper } from "../helpers/fetchWrapper";

export const coingeckoService = {
  fetchUsdPrice: async (asset: IAsset) => {
    const response: IApiResponse = await fetchWrapper.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${asset?.coingecko_id}&vs_currencies=usd`
    );
    return response;
  },
};
