import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IAsset, IDeployment } from "../../../commonTypes";
import { useStaking } from "../../../hooks/useStaking";
import DepositStake from "../../Menus/Wallet/DepositStake";
import { ButtonCancel } from "./styles";

const Wrapper = styled.div`
  padding: 32px 32px 36px 32px;
  padding-top: 16px;
`;

const StyledDepositStake = styled(DepositStake)`
  button {
    margin-bottom: 8px;
  }

  .amount {
    border-bottom: none;
  }

  .account-badge {
    border: 1px solid ${(props) => props.theme.inputBorderColor};
  }
`;

interface Props {
  onClose: () => void;
  onDepositContinueClick: () => void;
  isSuccess?: boolean;
  hideButtons?: boolean;
  asset: IAsset;
  deployment: IDeployment;
}

const DepositStakeCard = ({
  isSuccess,
  onClose,
  onDepositContinueClick,
  hideButtons,
  asset,
  deployment,
}: Props) => {
  const { t } = useTranslation();
  const { lastTxHash, error } = useStaking();

  return (
    <Wrapper>
      <StyledDepositStake
        handleDepositStakeMenuClose={onClose}
        onContinueClicked={onDepositContinueClick}
        hideButtons={hideButtons}
        asset={asset}
        deployment={deployment}
      />
      {!isSuccess && !lastTxHash && !error && (
        <ButtonCancel text={t("cancel")} fullWidth onClick={onClose} />
      )}
    </Wrapper>
  );
};

export default DepositStakeCard;
