import { BigNumber } from "@ethersproject/bignumber/lib/bignumber.js";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { settings } from "../../../../settings";
import { BidTypeEnum } from "../../../commonEnums";
import { IListing } from "../../../commonTypes";
import { getInitialBidAmount } from "../../../../helpers/listingHelper";
import { ethers } from "ethers";
import { useStakedQuery } from "../../../../queries/staking.query";

export function useBid(listing: IListing) {
  const { t } = useTranslation();
  const { data } = useStakedQuery();
  const isAuction = listing.type === BidTypeEnum.bid;
  const minBidIncreaseWei =
    isAuction && listing.buyer_id ? settings.MINIMUM_BID_INCREASE : "0";

  const [validationError, setValidationError] = useState("");
  const [bidAmount, setBidAmount] = useState<string>(
    getInitialBidAmount(listing.amount, minBidIncreaseWei, listing.asset)
  );

  // update validation error
  useEffect(() => {
    const minBidWei = BigNumber.from(listing.amount.toString()).add(
      minBidIncreaseWei
    );
    const minBidEth = ethers.utils.formatUnits(
      minBidWei.toString(),
      listing.asset.decimals
    );

    if (isAuction && Number(minBidEth) > Number(bidAmount)) {
      setValidationError(t("error-bid-too-low"));
    } else {
      setValidationError("");
    }
  }, [listing?.amount, listing?.asset, listing?.type, bidAmount]);

  return {
    staked: data?.stake,
    validationError,
    bidAmount,
    setBidAmount,
    isAuction,
  };
}
