import styled from "styled-components";
import Card from "../../Card/Card";
import Button from "../../Buttons/Button";
import { IListing } from "../../../commonTypes";
import {
  Paragraph16Bold,
  Paragraph16Regular,
} from "../../../Typography/Paragraph16";

import { useTranslation } from "react-i18next";
import {
  Paragraph14Regular,
  Paragraph14RegularSecondary,
} from "../../../Typography/Paragraph14";
import { Heading6Bold } from "../../../Typography/Heading6";
import fingersCrossed from "../../../../../public/images/fingers-crossed.png";
import { getVerticalGap } from "../../../styles";
import { Instruction, SuccessImg, SuccessImgWrapper } from "./styles";
import { useCoingeckoPrice } from "../../../../queries/coingecko.query";
import { tokenToUsdPriceLocaleString } from "../../../../helpers/tokenHelper";

const Wrapper = styled(Card)`
  background: ${(props) => props.theme.lightBackgroundColor};
  box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
  padding: 32px;
`;

const BidDetails = styled.div`
  background: ${(props) => props.theme.inputBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.md};
  padding: 24px;

  div {
    display: flex;
    justify-content: space-between;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${getVerticalGap("39px")}
  margin-bottom: 24px;
`;

const StyledUsername = styled(Paragraph16Bold)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  onClick: () => void;
  listing: IListing;
  amountEth: string;
}

const SuccessfulBid = ({ onClick, listing, amountEth }: Props) => {
  const { prices } = useCoingeckoPrice(listing.asset);
  const { t } = useTranslation();

  const amountUsd = tokenToUsdPriceLocaleString(
    amountEth,
    prices[listing.asset.coingecko_id]?.usd
  );

  return (
    <Wrapper>
      <Container>
        <SuccessImgWrapper>
          <SuccessImg
            src={fingersCrossed}
            width={100}
            height={100}
            layout="intrinsic"
          />
        </SuccessImgWrapper>
        <Instruction>
          <Paragraph16Regular>
            {t("bid-success", { name: listing?.music?.title })}
          </Paragraph16Regular>

          <StyledUsername>@{listing?.seller?.username}</StyledUsername>
        </Instruction>

        <BidDetails>
          <Paragraph14RegularSecondary>
            {t("your-bid")}
          </Paragraph14RegularSecondary>
          <div>
            <Heading6Bold>
              {Number(amountEth).toLocaleString()} {listing.asset.symbol}
            </Heading6Bold>
            <Paragraph14Regular>
              ${amountUsd.toLocaleString()}
            </Paragraph14Regular>
          </div>
        </BidDetails>
      </Container>

      <Button height="48px" text={t("view-item")} onClick={onClick} />
    </Wrapper>
  );
};

export default SuccessfulBid;
