import React from "react";
import styled from "styled-components";
import Tooltip, { ITooltipProps } from "../Tooltip/Tooltip";
import Icon from "../Icon/Icon";

const StyledIcon = styled(Icon)`
  cursor: pointer;
  opacity: 0.7;
`;

interface IProps extends Partial<ITooltipProps> {
  iconSize?: string;
}

const InfoTooltip = ({
  text,
  className,
  iconSize = "20px",
  ...props
}: IProps) => {
  return (
    <Tooltip text={text} {...props}>
      <StyledIcon name="info" size={iconSize} className={className} />
    </Tooltip>
  );
};

export default InfoTooltip;
