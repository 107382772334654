import { IModalProps } from "../Modal";
import LoginModal from "../LoginModal";
import PlaceBidModal from "./PlaceBidModal";
import { useWeb3 } from "@chainsafe/web3-context";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IListing } from "../../../commonTypes";
import { useSession } from "../../../../queries/authentication.query";
import { switchToNetwork } from "../../../../helpers/networkHelper";
import WrongNetworkModal from "../../WrongNetworkModal/WrongNetworkModal";

const PlaceBidModalWrapper = (
  props: IModalProps & { listing: IListing; onSuccess: () => void }
) => {
  const {
    onboard,
    checkIsReady,
    wallet,
    network: localNetwork,
    provider: localProvider,
  } = useWeb3();
  const { t } = useTranslation();
  const { isLoggedIn } = useSession();
  const [currentModal, setCurrentModal] = useState(isLoggedIn ? 1 : 0);
  const [showNetworkModal, setShowNetworkModal] = useState(false);

  useEffect(() => {
    async function connectWallet() {
      if (!wallet) {
        await onboard?.walletSelect();
      }
      await checkIsReady();
      setCurrentModal(1);
    }

    if (props.isOpen && currentModal > 0) {
      connectWallet();
    }
  }, [props.isOpen, checkIsReady]);

  useEffect(() => {
    setCurrentModal(isLoggedIn ? 1 : 0);
  }, [isLoggedIn]);

  useEffect(() => {
    // check that we are on the correct chain for this listing
    async function changeNetwork() {
      if (
        props.isOpen &&
        isLoggedIn &&
        localNetwork &&
        localNetwork !== props.listing.deployment.chain.id
      ) {
        await switchToNetwork(props.listing.deployment.chain, localProvider!)
          .then(() => setShowNetworkModal(false))
          .catch((e) => {
            setShowNetworkModal(true);
          });
      }
    }

    changeNetwork();
  }, [props.isOpen, localNetwork, isLoggedIn]);

  const handleOfferSent = () => {
    props.onSuccess();
  };

  const getCurrentModal = () => {
    if (props.isOpen) {
      if (showNetworkModal) {
        return (
          <WrongNetworkModal
            {...props}
            onClose={props.onClose!}
            targetChain={props.listing.deployment.chain}
          />
        );
      }

      switch (currentModal) {
        case 0: {
          return (
            <LoginModal {...props} instruction={t("login-modal-instruction")} />
          );
        }
        case 1: {
          return (
            <PlaceBidModal
              {...props}
              onOfferSent={handleOfferSent}
              onRefresh={props.onSuccess}
            />
          );
        }
        default: {
          return null;
        }
      }
    }

    return null;
  };

  return getCurrentModal();
};

export default PlaceBidModalWrapper;
