import styled from "styled-components";
import { getHeading6BoldTypography } from "../../../Typography";
import { getParagraph16RegularTypography } from "../../../Typography/Paragraph16";
import ButtonOutlined from "../../Buttons/ButtonOutlined";
import FadeImage from "../../FadeImage/FadeImage";
import { getVerticalGap } from "../../../styles";
import CoverComponent from "../../Cover";

export const Content = styled.div`
  color: ${(props) => props.theme.primaryColor};
  display: flex;
  justify-content: space-between;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    padding: 16px;
    ${getVerticalGap("16px")};
  }
`;

export const Track = styled.div`
  max-height: 440px;
  margin-right: 32px;
  max-width: 310px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 100%;
    margin: 0;
  }
`;

export const Bid = styled.div`
  background: ${(props) => props.theme.lightBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.md};
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 16px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const DetailsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 19px;
`;

export const Details = styled.div`
  flex: 1;
`;

export const Name = styled.h6`
  ${getHeading6BoldTypography};
  margin-bottom: 10px;
`;

export const Confirmation = styled.p`
  ${getParagraph16RegularTypography};
  margin-bottom: 32px;
  word-break: break-word;
`;

export const Label = styled.p`
  ${getParagraph16RegularTypography};
  color: ${(props) => props.theme.greyColor};
`;

export const Buttons = styled.div`
  margin-top: auto;

  button {
    margin-bottom: 8px;
  }
`;

export const ButtonCancel = styled(ButtonOutlined)`
  height: 48px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.semiTransparent};
  }
`;

export const CoverWrapper = styled.div`
  position: relative;
  align-self: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 100%;
  }
`;

export const Cover = styled(CoverComponent)`
  width: 300px;
  max-width: 100%;
  border-radius: ${(props) => props.theme.borderRadius.md};
  filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.5));
`;

export const ArtistAvatar = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

export const CurrentStep = styled.div`
  width: 385px;
  background: ${(props) => props.theme.lightBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.md};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const Instruction = styled.div`
  text-align: center;
  margin-bottom: 32px;
  overflow: hidden;

  p {
    display: inline;
  }
`;

export const SuccessImgWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  border-radius: 80px;
`;

export const SuccessImg = styled(FadeImage)`
  border-radius: 80px;
`;
