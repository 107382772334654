import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { MobileOnly, DesktopOnly } from "../styled";
import { getParagraph14BoldTypography } from "../../Typography";
import Icon from "../Icon/Icon";
import StaticLink from "../StaticLink/StaticLink";

const Root = styled.div``;

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  justify-content: flex-end;
  padding-bottom: 30px;
  text-decoration: none;
  align-items: center;
  justify-items: center;
`;

const SeeAllContainer = styled.div`
  padding-top: 7px;
`;

const SeeAllLink = styled(StaticLink)`
  ${getParagraph14BoldTypography};
  color: ${({ theme }) => theme.primaryColor};
  line-height: 18px;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
`;

const NavigationButton = styled.button`
  width: 42px;
  height: 40px;
  cursor: pointer;
  border: 2px solid #2b2b2b;
  border-radius: 6px;
  background: none;
`;

export enum CarouselNavigationEnum {
  DEFAULT,
  SEE_ALL,
}

interface INavigationProps {
  seeAllPath: string;
  previous: () => void;
  next: () => void;
  type?: CarouselNavigationEnum;
}

const CarouselNavigation = ({
  seeAllPath,
  previous,
  next,
  type,
}: INavigationProps) => {
  const { t } = useTranslation();

  if (type === CarouselNavigationEnum.SEE_ALL) {
    return (
      <Root>
        <SeeAllContainer>
          <SeeAllLink href={seeAllPath}>{t("main-section-see-all")}</SeeAllLink>
        </SeeAllContainer>
      </Root>
    );
  }

  return (
    <Root>
      <MobileOnly>
        <SeeAllContainer>
          <SeeAllLink href={seeAllPath}>{t("main-section-see-all")}</SeeAllLink>
        </SeeAllContainer>
      </MobileOnly>
      <DesktopOnly>
        <Container>
          <NavigationButton onClick={previous}>
            <Icon name="chevronLeft" size="16px" />
          </NavigationButton>
          <NavigationButton onClick={next}>
            <Icon name="chevronRight" size="16px" />
          </NavigationButton>
        </Container>
      </DesktopOnly>
    </Root>
  );
};

export default CarouselNavigation;
