import styled from "styled-components";
import { Paragraph14RegularSecondary } from "../../../Typography/Paragraph14";
import Card from "../../Card/Card";
import MenuNav from "../../Menus/MenuNav";
import Button from "../../Buttons/Button";
import Staked from "../../Menus/Account/Staked";
import Locked from "../../Menus/Account/Locked";
import { useTranslation } from "react-i18next";
import { IAsset } from "../../../commonTypes";

const Wrapper = styled(Card)`
  background: ${(props) => props.theme.lightBackgroundColor};
  height: 100%;
  box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
  justify-content: unset;

  .expand {
    background: ${(props) => props.theme.inputBackgroundColor} !important;
  }

  button {
    margin-top: 32px;
  }
`;

const Instruction = styled(Paragraph14RegularSecondary)`
  text-align: center;
  margin-bottom: 32px;
`;

interface Props {
  goBack: () => void;
  onClick: () => void;
  asset: IAsset;
}

const NotEnoughStaked = ({ onClick, goBack, asset }: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <MenuNav onBackClicked={goBack} title={t("not-enough-staked")} />
      <Instruction>
        {t("stake-explainer", {
          symbol: asset.symbol,
        })}
      </Instruction>

      <Staked />
      <Locked />

      <Button text={t("increase-stake")} onClick={onClick} />
    </Wrapper>
  );
};

export default NotEnoughStaked;
