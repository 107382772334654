import React from "react";
import styled from "styled-components";
import {
  getHeading4BoldTypography,
  getHeading6BoldTypography,
  getHeading6RegularTypography,
  getParagraph14RegularTypography,
} from "../../Typography";
import Countdown from "../Countdown";

const Root = styled.div``;

const Container = styled.div`
  margin-right: 10px;
`;

const Title = styled.p<any>`
  ${getHeading6RegularTypography};
  color: ${({ theme }) => theme.primaryColor};
  opacity: ${(props) => (props.opacity ? props.opacity : "")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "0px"};
  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    ${getParagraph14RegularTypography};
  }
`;

const Timer = styled.h4`
  ${getHeading4BoldTypography};
  color: ${({ theme }) => theme.primaryColor};
  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    ${getHeading6BoldTypography};
  }
`;

type Props = {
  title: string;
  titleMarginBottom?: string;
  opacity?: string;
  endTime: string;
  onComplete: () => void;
  showWhenEnded?: boolean;
};

const AuctionTimer = ({
  title,
  endTime,
  opacity,
  titleMarginBottom,
  onComplete,
  showWhenEnded,
}: Props) => {
  return (
    <Root>
      <Container>
        <Title marginBottom={titleMarginBottom} opacity={opacity}>
          {title}
        </Title>
        <Timer>
          <Countdown
            endTime={endTime}
            showWhenEnded={showWhenEnded}
            onComplete={onComplete}
          />
        </Timer>
      </Container>
    </Root>
  );
};

export default AuctionTimer;
