import { useTranslation } from "react-i18next";
import { getCurrencyLabel } from "../../../../helpers/format";
import { IListing } from "../../../commonTypes";
import Button from "../../Buttons/Button";
import { ButtonCTA, ErrorWrapper } from "../../styled";
import AmountInput from "../../AmountInput";
import { Bid, Confirmation, Buttons, ButtonCancel } from "./styles";
import TransactionSummary from "./TransactionSummary";
import styled from "styled-components";
import { useBid } from "./useBid";
import { useWeb3 } from "@chainsafe/web3-context";
import { useStakedQuery } from "../../../../queries/staking.query";
import { ethers } from "ethers";
import useChain from "../../../hooks/useChain";

const Error = styled(ErrorWrapper)`
  margin-bottom: 3px;
`;

const BidInput = ({
  listing,
  strings,
  isBid,
  error,
  isLoading,
  onClose,
  handleBuyClick,
}: {
  listing: IListing;
  isBid: boolean;
  strings: {
    confirmation: string;
    userName: string;
    cta: string;
  };
  error: string;
  isLoading: boolean;
  onClose?: () => void;
  handleBuyClick: (amount: string) => void;
}) => {
  const { t } = useTranslation();
  const { validationError, bidAmount, setBidAmount } = useBid(listing);
  const { address } = useWeb3();
  const { stakingContract: contract } = useChain();
  const { data: stakedResult } = useStakedQuery(
    contract,
    address!,
    listing.asset,
    listing.deployment
  );

  const onBidAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBidAmount(e.target.value);
  };

  return (
    <Bid>
      <Confirmation>
        {strings.confirmation}
        {` ${t("from")} @${strings.userName}`}
      </Confirmation>
      {isBid && (
        <AmountInput
          amount={bidAmount}
          onChange={onBidAmountChange}
          label={getCurrencyLabel(listing.asset)}
          min={0}
          step={1}
        />
      )}
      <TransactionSummary
        availableStakingBalance={ethers.utils.formatUnits(
          stakedResult?.stake ?? "0",
          listing.asset.decimals
        )}
        bidAmount={bidAmount}
        asset={listing.asset}
        version={listing.deployment.name}
      />
      <Buttons>
        {error && <Error>{error}</Error>}
        {validationError && <Error>{validationError}</Error>}
        <Button
          isLoading={isLoading}
          label={<ButtonCTA>{strings.cta}</ButtonCTA>}
          fullWidth
          height="48px"
          onClick={() => handleBuyClick(bidAmount)}
          disabled={!!validationError}
        />
        {!isLoading && (
          <ButtonCancel
            label={<ButtonCTA>{t("cancel")}</ButtonCTA>}
            onClick={onClose}
            isLoading={isLoading}
          />
        )}
      </Buttons>
    </Bid>
  );
};

export default BidInput;
