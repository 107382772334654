import { useWeb3 } from "@chainsafe/web3-context";
import { useState } from "react";
import { StakingClient } from "../../../../helpers/stakingClient";
import { IListing } from "../../../commonTypes";

export const usePlaceBid = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const { address, provider } = useWeb3();

  const placeBid = async (
    listing: IListing,
    amount: string
  ): Promise<IListing | undefined> => {
    try {
      setIsLoading(true);
      setError("");

      const client = new StakingClient(
        listing.deployment,
        provider!.getSigner()
      );

      const res = await client.placeBid(listing, amount, address!);

      return res.data;
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    placeBid,
    isLoading,
    setIsLoading,
    error,
    setError,
  };
};
