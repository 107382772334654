import styled from "styled-components";
import {
  getHeading4BoldTypography,
  getHeading6BoldTypography,
} from "../../Typography";
import Card from "../Card/Card";
import { useTranslation } from "react-i18next";
import ButtonOutlined from "../Buttons/ButtonOutlined";
import { Paragraph16Bold } from "../../Typography/Paragraph16";
import { getAbsoluteFill, getFlexCenter } from "../../styles";
import Icon from "../Icon/Icon";

const StyledCard = styled(Card)`
  height: 100%;
`;

export const SeeAllCardContentWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  color: ${({ theme }) => theme.primaryColor};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background: url("/images/see-all-card.png"),
    linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%),
    rgba(255, 255, 255, 0.4);
  background-size: cover;
`;

const SeeAllCardContent = styled.div`
  ${getAbsoluteFill};
  ${getFlexCenter};
  flex-direction: column;
`;

export const SeeAllCardLabel = styled.p`
  margin-top: 30px;
  ${getHeading4BoldTypography};
  color: ${({ theme }) => theme.primaryColor};
  text-align: center;
  white-space: pre-line;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileS}) {
    ${getHeading6BoldTypography};
  }
`;

const ActionWrapper = styled.div`
  padding-top: 10px;
`;

const BottomButtonLabel = styled(Paragraph16Bold)`
  line-height: 18px;
`;

interface ISeeAllCardProps {
  to: string;
  label: string;
  className?: string;
}

const SeeAllCard = ({ to, label, className }: ISeeAllCardProps) => {
  const { t } = useTranslation();
  return (
    <StyledCard className={className}>
      <SeeAllCardContentWrapper>
        <SeeAllCardContent>
          <Icon height="50px" width="34px" name="lightning" />
          <SeeAllCardLabel>{label}</SeeAllCardLabel>
        </SeeAllCardContent>
      </SeeAllCardContentWrapper>
      <ActionWrapper>
        <ButtonOutlined
          fullWidth
          href={to}
          label={
            <BottomButtonLabel>
              {t("main-section-view-more-button")}
            </BottomButtonLabel>
          }
        />
      </ActionWrapper>
    </StyledCard>
  );
};

export default SeeAllCard;
