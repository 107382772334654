import styled, { css } from "styled-components";
import { Heading4Bold } from "../../Typography/Heading4";
import { SwiperOptions } from "swiper";

export const Root = styled.div``;

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Heading4Bold)`
  line-height: 33px;
  padding-bottom: 34px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    font-size: ${({ theme }) => theme.heading.sm};
    line-height: 28px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 489px;
`;

export const StyledCarousel = styled.div`
  width: 100%;
  position: relative;

  .swiper-container-noizd {
    width: 100% !important;
    height: 100% !important;
  }

  .swiper-slide {
    height: unset;
    display: flex;
  }
`;

const getPlaceholderItemStyle = (
  visibleItems: number,
  spaceBetween: number
) => {
  const percentageWidth = Math.round((100 * 100) / visibleItems) / 100;
  const margin = Math.round(((visibleItems - 1) * spaceBetween) / visibleItems);

  return css`
    flex-basis: calc(${percentageWidth}% - ${margin}px);
    margin-right: ${spaceBetween}px;
  `;
};

export const CarouselItemPlaceholder = styled.div`
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
`;

export const CarouselPlaceholder = styled.div<{
  slidesPerView: number;
  spaceBetween: number;
  breakpoints?: { [width: string]: SwiperOptions };
}>`
  width: 100%;
  position: relative;
  display: flex;
  overflow: hidden;

  ${CarouselItemPlaceholder} {
    ${({ slidesPerView, spaceBetween, breakpoints }) =>
      css`
        ${getPlaceholderItemStyle(slidesPerView, spaceBetween)};

        ${breakpoints &&
        Object.keys(breakpoints).map((breakpoint) => {
          const options: SwiperOptions = breakpoints[breakpoint];

          return css`
            @media (min-width: ${breakpoint}px) {
              ${getPlaceholderItemStyle(
                (options.slidesPerView as number) || slidesPerView,
                (options.spaceBetween as number) || spaceBetween
              )};
            }
          `;
        })}
      `}
  }
`;
