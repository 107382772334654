import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { truncateAmount } from "../../../../helpers/format";
import { IAsset } from "../../../commonTypes";
import { getParagraph16BoldTypography } from "../../../Typography";
import { getParagraph16RegularTypography } from "../../../Typography/Paragraph16";
import InfoTooltip from "../../InfoTooltip/InfoTooltip";

const TransactionSummaryContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 32px;
`;

const TransactionListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const Value = styled.p`
  ${getParagraph16BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.p`
  ${getParagraph16RegularTypography};
  color: ${(props) => props.theme.greyColor};
`;

const StyledInfoTooltip = styled(InfoTooltip)`
  margin-left: 4px;
  transform: translateY(-1px);
`;

type Props = {
  availableStakingBalance?: string;
  bidAmount?: string;
  asset?: IAsset;
  version?: string;
};

const TransactionSummary = ({
  availableStakingBalance,
  bidAmount,
  asset,
  version,
}: Props) => {
  const { t } = useTranslation();

  return (
    <TransactionSummaryContainer>
      <TransactionListItem>
        <Label>
          {t("your-balance")} on {version}
        </Label>
        <Value>
          {truncateAmount(availableStakingBalance)} {asset?.symbol}
        </Value>
      </TransactionListItem>

      <TransactionListItem>
        <LabelWrapper>
          <Label>{t("service-fee")}</Label>
          <StyledInfoTooltip
            text={t("service-fee-info")}
            placement="top"
            iconSize="18px"
          />
        </LabelWrapper>
        <Value>0 {asset?.symbol}</Value>
      </TransactionListItem>

      <TransactionListItem>
        <Label>{t("you-will-pay")}</Label>
        <Value>
          {truncateAmount(bidAmount) || 0} {asset?.symbol}
        </Value>
      </TransactionListItem>
    </TransactionSummaryContainer>
  );
};

export default TransactionSummary;
