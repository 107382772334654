import { useState } from "react";

interface UseTimerProps {
  inProgressLabel: string;
  endedLabel: string;
}

export const useTimer = ({ inProgressLabel, endedLabel }: UseTimerProps) => {
  const [label, setLabel] = useState(inProgressLabel);
  const [isComplete, setIsComplete] = useState(false);

  function onTimerCompleted() {
    setLabel(endedLabel);
    setIsComplete(true);
  }

  return {
    label,
    onTimerCompleted,
    isComplete,
  };
};
