import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getParagraph14RegularTypography } from "../../../Typography";
import Modal, { IModalProps } from "../Modal";
import Copy from "../../Copy";
import { useEffect, useState } from "react";
import { getFlexCenter, getHorizontalGap } from "../../../styles";
import Icon from "../../Icon/Icon";
import { IFile } from "../../../commonTypes";
import { IMAGE_SIZES } from "../../../../constants/imageSizes";
import Cover from "../../Cover";
import { Paragraph14RegularSecondary } from "../../../Typography/Paragraph14";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CoverWrapper = styled.div`
  width: 100%;
  max-width: 248px;
  border-radius: ${(props) => props.theme.borderRadius.md};
  align-self: center;
  position: relative;
`;

const Icons = styled.div`
  display: flex;
  margin: 28px 0 32px 0;
  ${getHorizontalGap("20px")};
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  cursor: pointer;
  ${getFlexCenter};
`;

const Twitter = styled(IconWrapper)`
  background: ${(props) => props.theme.lightBackgroundColor};
`;

const Facebook = styled(IconWrapper)`
  background: ${(props) => props.theme.lightBackgroundColor};
  margin-right: 0;
`;

const CopyText = styled(Paragraph14RegularSecondary)`
  text-align: center;
  margin-bottom: 20px;
`;

const CopyLink = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.theme.lightBackgroundColor};
  padding: 17px;
  border-radius: ${(props) => props.theme.borderRadius.sm};
`;

const Link = styled.p`
  ${getParagraph14RegularTypography};
  color: ${(props) => props.theme.primaryColor};
  text-align: center;
  word-break: break-all;
`;

const CopyWrapper = styled.div`
  margin-left: 5px;
`;

const Info = styled.p`
  ${getParagraph14RegularTypography};
  color: ${(props) => props.theme.accent.normal};
  margin-top: 6px;
`;

type Props = Omit<IModalProps, "title"> & {
  url: string;
  image: IFile;
};

const ShareModal = ({ url, isOpen, onClose, image, ...props }: Props) => {
  const { t } = useTranslation();
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const getUrl = () => encodeURI(url);

  const onTwitterClicked = () => {
    window.open(`https://twitter.com/intent/tweet?url=${getUrl()}`);
  };
  const onFacebookClicked = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${getUrl()}`);
  };

  const handleCopySuccess = () => {
    setShowCopySuccess(true);
  };

  useEffect(() => {
    if (showCopySuccess) {
      const timeoutId = setTimeout(() => {
        setShowCopySuccess(false);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [showCopySuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("share-share-with-friends")}
      {...props}
    >
      <Container>
        <CoverWrapper>
          <Cover
            url={image.url}
            mime={image.mime}
            size={IMAGE_SIZES.trackCoverArt.medium}
          />
        </CoverWrapper>
        <Icons>
          <Twitter onClick={onTwitterClicked}>
            <Icon name="twitterShare" size="18px" />
          </Twitter>
          <Facebook onClick={onFacebookClicked}>
            <Icon name="facebookShare" size="18px" />
          </Facebook>
        </Icons>

        <CopyText>{t("share-copy-link")}</CopyText>
        <CopyLink>
          <Link>{url}</Link>
          <CopyWrapper>
            <Copy payload={getUrl()} onCopy={handleCopySuccess} />
          </CopyWrapper>
        </CopyLink>
        {showCopySuccess && <Info>{t("clipboard-copy-success-message")}</Info>}
      </Container>
    </Modal>
  );
};

export default ShareModal;
