import styled from "styled-components";
import Card from "../../Card/Card";
import { IListing } from "../../../commonTypes";
import {
  Paragraph16Bold,
  Paragraph16Regular,
} from "../../../Typography/Paragraph16";
import party from "../../../../../public/images/party.png";
import ButtonOutlined from "../../Buttons/ButtonOutlined";
import { useTranslation } from "react-i18next";
import { Instruction, SuccessImg, SuccessImgWrapper } from "./styles";

const Wrapper = styled(Card)`
  background: ${(props) => props.theme.lightBackgroundColor};
  height: 100%;
  box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
  display: flex;
  justify-content: space-around;
`;

const StyledButton = styled(ButtonOutlined)`
  height: 48px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
`;

const StyledUsername = styled(Paragraph16Bold)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface Props {
  onClick: () => void;
  listing?: IListing;
}

const SuccessfulPurchase = ({ onClick, listing }: Props) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <SuccessImgWrapper>
        <SuccessImg src={party} width={100} height={100} layout="intrinsic" />
      </SuccessImgWrapper>
      <Instruction>
        <Paragraph16Regular>
          {t("purchase-success", { name: listing?.music?.title })}
        </Paragraph16Regular>
        <StyledUsername>@{listing?.seller?.username}</StyledUsername>
      </Instruction>

      <StyledButton text={t("view-item")} onClick={onClick} />
    </Wrapper>
  );
};

export default SuccessfulPurchase;
