export const breakpoints = {
  280: {
    slidesPerView: 1.15,
    slidesPerGroup: 1.15,
    spaceBetween: 14,
  },
  650: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 14,
  },
  768: {
    slidesPerView: 2.15,
    slidesPerGroup: 2,
    spaceBetween: 24,
  },
  1024: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 24,
  },
  1280: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 34,
  },
};
