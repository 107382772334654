import { listingToBid } from "../../../helpers/bids";
import { IListing } from "../../commonTypes";
import { useCoingeckoPrice } from "../../../queries/coingecko.query";
import { ethers } from "ethers";

export default function useBidCard(listing: IListing) {
  const { prices } = useCoingeckoPrice(listing?.asset);

  let price = listing.amount;
  let priceUsd = 0;
  const title = listing?.music.title;
  const maxBid = listingToBid(listing);
  const endTime = listing?.ending;

  if (listing) {
    const usdExchangeRate = prices[listing?.asset?.coingecko_id]?.usd ?? "0";

    price = ethers.utils.formatUnits(price!, listing.asset.decimals);

    priceUsd = Number(price) * Number(usdExchangeRate);
  }

  return {
    title,
    price: Number(price).toLocaleString(),
    priceUsd: Number(priceUsd).toLocaleString(),
    currency: listing?.asset?.symbol ?? "eth",
    maxBid,
    endTime,
  };
}
