import styled, { useTheme } from "styled-components";
import {
  Paragraph14Regular,
  Paragraph14RegularSecondary,
} from "../../../Typography/Paragraph14";
import Card from "../../Card/Card";
import FadeImage from "../../FadeImage/FadeImage";
import MenuNav from "../../Menus/MenuNav";
import cry from "../../../../../public/images/cry.png";
import { Dot } from "../../styled";
import { Heading6Bold } from "../../../Typography/Heading6";
import Button from "../../Buttons/Button";
import { useTranslation } from "react-i18next";
import { getHorizontalGap } from "../../../styles";
import InfoTooltip from "../../InfoTooltip/InfoTooltip";
import { IAsset } from "../../../commonTypes";

const Wrapper = styled(Card)`
  background: ${(props) => props.theme.lightBackgroundColor} !important;
  position: relative;
  text-align: center;

  button {
    margin-top: 32px;
  }
`;

const CryImage = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${getHorizontalGap("4px")};
`;

const StakedAmount = styled.div`
  background: rgba(255, 80, 100, 0.05);
  border: 1px solid ${(props) => props.theme.red};
  border-radius: ${(props) => props.theme.borderRadius.md};
  text-align: left;
  padding: 16px;
  margin-top: 16px;
  div {
    display: flex;
    align-items: center;

    div {
      margin-right: 8px;
    }
  }
`;

interface Props {
  goBack: () => void;
  onClick: () => void;
  asset: IAsset;
}

const NothingStaked = ({ onClick, goBack, asset }: Props) => {
  const theme = useTheme();
  const stakedBalance = 0;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <MenuNav onBackClicked={goBack} title={t("nothing-staked")} />
      <CryImage>
        <FadeImage src={cry} width={100} height={100} layout="intrinsic" />
      </CryImage>
      <InfoWrapper>
        <Paragraph14Regular>
          {t("stake-explainer", {
            symbol: asset.symbol,
          })}
        </Paragraph14Regular>
        <InfoTooltip
          text={t("stake-explainer-detailed")}
          placement="topRight"
        />
      </InfoWrapper>

      <StakedAmount>
        <Paragraph14RegularSecondary>
          {t("stake-staked-available")}
        </Paragraph14RegularSecondary>
        <div>
          <Dot color={theme.red} />
          <Heading6Bold>
            {stakedBalance} {asset.symbol}
          </Heading6Bold>
        </div>
      </StakedAmount>
      <Button text={t("increase-stake")} onClick={onClick} />
    </Wrapper>
  );
};

export default NothingStaked;
