import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { parseUnits } from "@ethersproject/units/lib";
import Modal, { IModalProps } from "../Modal";
import React, { ReactNode, useEffect, useState } from "react";
import BidButton from "../../NftCard/BidButton";
import BidDetails from "../../NftCard/BidDetails";
import { BidTypeEnum, ListingStatusEnum } from "../../../commonEnums";
import { usePlaceBid } from "./usePlaceBid";
import { IListing } from "../../../commonTypes";
import {
  ArtistAvatar,
  Content,
  Cover,
  CoverWrapper,
  CurrentStep,
  Details,
  DetailsContainer,
  Name,
  Track,
} from "./styles";
import { useStaking } from "../../../hooks/useStaking";
import NotEnoughStaked from "./NotEnoughStaked";
import NothingStaked from "./NothingStaked";
import BidInput from "./BidInput";
import DepositStakeCard from "./DepositStakeCard";
import SuccessfulBid from "./SuccessfulBid";
import SuccessfulPurchase from "./SuccessfulPurchase";
import UserAvatar from "../../UserAvatar";
import { routes } from "../../../../routes";
import { getListingBid } from "../../../../helpers/bids";
import { useWeb3 } from "@chainsafe/web3-context";
import { IMAGE_SIZES } from "../../../../constants/imageSizes";
import { captureException } from "@sentry/nextjs";
import { useStakedQuery } from "../../../../queries/staking.query";
import { useContractsForDeployment } from "../../../../context/Contracts";
import { useListingIssuesQuery } from "../../../../queries/listingIssues.query";

type Props = IModalProps & {
  onOfferSent: () => void;
  onRefresh: () => void;
};

enum PlaceBidSteps {
  bidInput,
  nothingStaked,
  notEnoughStaked,
  successfulPurchase,
  successfulBid,
  depositCard,
}

const PlaceBidModal = ({
  listing,
  ...props
}: Props & { listing: IListing }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { placeBid, isLoading: isBidLoading, error } = usePlaceBid();
  const { checkIsReady } = useWeb3();
  const {
    isLoading: isStakingLoading,
    clearTransaction,
    success,
  } = useStaking();
  const { address } = useWeb3();
  const { stakingContract } = useContractsForDeployment(listing.deployment);
  const { data: stakedResult } = useStakedQuery(
    stakingContract!,
    address!,
    listing?.asset,
    listing.deployment
  );
  const issueDetails = useListingIssuesQuery(listing);
  const [isBidProcessing, setIsBidProcessing] = useState(false);
  const [currentStep, setCurrentStep] = useState<PlaceBidSteps>(
    PlaceBidSteps.bidInput
  );
  const [bidEth, setBidEth] = useState("0");

  const isAuction = listing.type === BidTypeEnum.bid;
  const isLoading = isStakingLoading || isBidLoading;

  // available stake
  // const stakedAmount = Number(staked?.available ?? 0);
  const stakedAmount = Number(stakedResult?.stake.toString());

  useEffect(() => {
    // if listing is not active anymore and we don't currently show success screen then we should close modal
    if (
      !isBidProcessing &&
      listing.status !== ListingStatusEnum.active &&
      ![PlaceBidSteps.successfulBid, PlaceBidSteps.successfulPurchase].includes(
        currentStep
      )
    ) {
      props.onClose?.();
    }
  }, [listing.status, currentStep, isBidProcessing]);

  const handleBuyClicked = async (bidAmount: string) => {
    try {
      const isConnected = await checkIsReady();
      if (isConnected) {
        setBidEth(bidAmount);
        // staked amount in WEI
        const bidAmountWei = parseUnits(
          bidAmount.toString(),
          listing.asset.decimals
        );

        if (stakedAmount === 0) {
          setCurrentStep(PlaceBidSteps.nothingStaked);
          return;
        }

        if (stakedAmount < Number(bidAmountWei)) {
          setCurrentStep(PlaceBidSteps.notEnoughStaked);
          return;
        }

        setIsBidProcessing(true);
        let res = await placeBid(listing, bidAmountWei.toString());
        if (res) {
          if (!isAuction) {
            setCurrentStep(PlaceBidSteps.successfulPurchase);
          } else {
            setCurrentStep(PlaceBidSteps.successfulBid);
          }
        }
      }
    } catch (error) {
      captureException(error);
    } finally {
      setIsBidProcessing(false);
    }
  };

  const handleSuccessBidPurchase = () => {
    props.onOfferSent();
    router.push(routes.marketplace.id(listing.music_id, listing.id));
    props.onClose && props.onClose();
  };

  const handleBidButtonClick = () => {
    props.onClose && props.onClose();
  };

  const handleOnClose = () => {
    if (
      currentStep === PlaceBidSteps.successfulBid ||
      currentStep === PlaceBidSteps.successfulPurchase
    ) {
      props.onRefresh();
    }

    if (currentStep === PlaceBidSteps.depositCard) {
      setCurrentStep(PlaceBidSteps.bidInput);
      clearTransaction();
    }
    props.onClose && props.onClose();
  };

  const isBid = listing?.type === BidTypeEnum.bid;
  const strings = {
    title: isBid ? t("make-a-bid") : t("make-an-offer"),
    confirmation: `${
      isBid ? t("about-to-place-bid") : t("about-to-purchase")
    } ${listing?.music.title}`,
    userName: listing?.artist.username,
    cta: isBid ? t("place-an-offer") : t("confirm-purchase"),
  };

  const shouldCloseOnOverlayClick = currentStep === PlaceBidSteps.bidInput;

  function getCurrentStepComponent(step: PlaceBidSteps): ReactNode {
    switch (step) {
      case PlaceBidSteps.bidInput: {
        return (
          <BidInput
            strings={strings}
            isLoading={isLoading}
            handleBuyClick={handleBuyClicked}
            error={error}
            isBid={isBid}
            listing={listing}
            onClose={props.onClose}
          />
        );
      }
      case PlaceBidSteps.notEnoughStaked: {
        return (
          <NotEnoughStaked
            asset={listing.asset}
            onClick={() => {
              clearTransaction();
              setCurrentStep(PlaceBidSteps.depositCard);
            }}
            goBack={() => {
              setCurrentStep(PlaceBidSteps.bidInput);
            }}
          />
        );
      }
      case PlaceBidSteps.nothingStaked: {
        return (
          <NothingStaked
            asset={listing.asset}
            onClick={() => {
              setCurrentStep(PlaceBidSteps.depositCard);
            }}
            goBack={() => {
              setCurrentStep(PlaceBidSteps.bidInput);
            }}
          />
        );
      }
      case PlaceBidSteps.depositCard: {
        return (
          <DepositStakeCard
            isSuccess={!!success?.transactionHash && !!success?.blockNumber}
            onClose={() => {
              setCurrentStep(PlaceBidSteps.bidInput);
              clearTransaction();
            }}
            onDepositContinueClick={() => {
              setCurrentStep(PlaceBidSteps.bidInput);
              clearTransaction();
            }}
            hideButtons
            asset={listing.asset}
            deployment={listing.deployment}
          />
        );
      }
      case PlaceBidSteps.successfulBid: {
        return (
          <SuccessfulBid
            onClick={handleSuccessBidPurchase}
            listing={listing}
            amountEth={bidEth}
          />
        );
      }
      case PlaceBidSteps.successfulPurchase: {
        return (
          <SuccessfulPurchase
            onClick={handleSuccessBidPurchase}
            listing={listing}
          />
        );
      }
      default: {
        return (
          <BidInput
            strings={strings}
            isLoading={isLoading}
            handleBuyClick={handleBuyClicked}
            error={error}
            isBid={isBid}
            listing={listing}
            onClose={props.onClose}
          />
        );
      }
    }
  }

  return (
    <Modal
      {...props}
      onClose={handleOnClose}
      title={strings.title}
      maxWidth="fit-content"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <Content>
        <Track>
          <CoverWrapper>
            <Cover
              url={listing?.music?.cover?.url}
              mime={listing?.music?.cover?.mime}
              size={IMAGE_SIZES.trackCoverArt.medium}
            />
            {listing?.artist && (
              <ArtistAvatar>
                <UserAvatar user={listing?.artist} size="34px" />
              </ArtistAvatar>
            )}
          </CoverWrapper>
          <DetailsContainer>
            <Details>
              <Name>{listing?.music?.title}</Name>
              <BidDetails
                price={listing?.amount}
                bidType={listing?.type}
                asset={listing.asset}
                issueDetails={issueDetails}
              />
            </Details>
          </DetailsContainer>
          {isBid && (
            <BidButton
              bid={getListingBid(listing)}
              redirectUrl={routes.marketplace.id(listing.music_id) + "#history"}
              onClick={handleBidButtonClick}
              listing={listing}
            />
          )}
        </Track>
        <CurrentStep>{getCurrentStepComponent(currentStep)}</CurrentStep>
      </Content>
    </Modal>
  );
};

export default PlaceBidModal;
