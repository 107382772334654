import { useQuery } from "react-query";
import { coingeckoService } from "../api/coingeckoService";
import { IAsset, ICoingeckoPrices } from "../common/commonTypes";

const initialPrices: ICoingeckoPrices = {};

export const useCoingeckoPrice = (asset?: IAsset) => {
  const queryResponse = useQuery<ICoingeckoPrices>(
    [asset?.id],
    async () => {
      const response = await coingeckoService.fetchUsdPrice(asset!);

      return response.data;
    },
    {
      staleTime: Infinity,
      refetchInterval: 60 * 1000,
      enabled: asset && asset.id.length > 0,
    }
  );

  return {
    prices: queryResponse.data || initialPrices,
    ...queryResponse,
  };
};
