import React, { ReactNode, useRef } from "react";
import { SwiperOptions } from "swiper";
import { Swiper, SwiperCore, SwiperSlide } from "../Swiper/Swiper";
import CarouselNavigation, {
  CarouselNavigationEnum,
} from "./CarouselNavigration";
import { breakpoints as defaultBreakpoints } from "./carouselBreakpoints";
import useMobileResponsive from "../../hooks/useMobileResponsive";
import LoadingSpinner from "../LoadingSpinner";
import {
  Root,
  NavigationContainer,
  Title,
  LoaderContainer,
  CarouselPlaceholder,
  Container,
  CarouselItemPlaceholder,
  StyledCarousel,
} from "./Carousel.styled";
import { useIsClientRender } from "../../hooks/useIsClientRender";

const MAX_MOBILE_ITEMS_COUNT = 8;

interface ICarouselProps<ItemType> {
  title: string;
  items: ItemType[];
  renderItem: (item: ItemType) => ReactNode;
  keyExtractor: (item: ItemType) => string;
  isLoading: boolean;
  redirectUrl: string;
  slidesPerView?: number;
  spaceBetween?: number;
  breakpoints?: { [width: string]: SwiperOptions };
  navigationType?: CarouselNavigationEnum;
  seeAllComponent?: React.ReactNode;
  autoplay?: boolean;
}

function Carousel<ItemType>({
  title,
  items,
  renderItem,
  keyExtractor,
  redirectUrl,
  isLoading,
  slidesPerView = 1.15,
  spaceBetween = 14,
  breakpoints = defaultBreakpoints,
  navigationType,
  seeAllComponent,
  autoplay = false,
}: ICarouselProps<ItemType>) {
  const swiperRef = useRef<SwiperCore>();
  const { isDesktop, isTablet } = useMobileResponsive();
  const isClientRender = useIsClientRender();
  const limitedItems = isDesktop
    ? items
    : items.slice(0, MAX_MOBILE_ITEMS_COUNT);

  const previous = () => {
    swiperRef.current?.slidePrev();
  };

  const next = () => {
    swiperRef.current?.slideNext();
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <LoaderContainer>
          <LoadingSpinner />
        </LoaderContainer>
      );
    }

    if (!isClientRender) {
      // we need to add custom placeholder on server render, because swiper is not able to properly calculate
      // elements width without access to window object
      return (
        <CarouselPlaceholder
          slidesPerView={slidesPerView}
          spaceBetween={spaceBetween}
          breakpoints={breakpoints}
        >
          {limitedItems.map((item: ItemType) => (
            <CarouselItemPlaceholder key={keyExtractor(item)}>
              {renderItem(item)}
            </CarouselItemPlaceholder>
          ))}
        </CarouselPlaceholder>
      );
    }

    return (
      <StyledCarousel>
        <Swiper
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          slidesPerView={slidesPerView}
          spaceBetween={spaceBetween}
          threshold={5}
          breakpoints={breakpoints}
          className="swiper-container-noizd"
          speed={700}
          autoplay={
            autoplay && {
              delay: 5000,
              disableOnInteraction: isTablet,
              pauseOnMouseEnter: true,
            }
          }
        >
          {limitedItems.map((item: ItemType) => (
            <SwiperSlide key={keyExtractor(item)}>
              {renderItem(item)}
            </SwiperSlide>
          ))}
          {!isDesktop && seeAllComponent && (
            <SwiperSlide>{seeAllComponent}</SwiperSlide>
          )}
        </Swiper>
      </StyledCarousel>
    );
  };

  return (
    <Root>
      <NavigationContainer>
        <Title>{title}</Title>
        <CarouselNavigation
          previous={previous}
          next={next}
          seeAllPath={redirectUrl}
          type={navigationType}
        />
      </NavigationContainer>
      <Container>{renderContent()}</Container>
    </Root>
  );
}

export default Carousel;
